.section-about-gala {
    padding-bottom: 0;
    background: var(--header-bg-color);

    * {
        color: white;
    }

    iframe {
        width: 100%;
        height: 50vh;
        max-height: 400px;
        min-height: 300px;
        margin-bottom: 50px;
    }


    .headingContainer {
        border-radius: 16px;
        background-image: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
        padding: 1px 5px;
        .headingWrapper {
            background-image: url('../../assets/headingBg.jpg');
            background-size: cover;
            border-radius: 16px;
            .heading {
                text-align: center;
                text-transform: uppercase;
                background: -webkit-linear-gradient(left, #FCEDB7, #FCF9E2, #E0CA98, #FBF1C2);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 5px 0;
                padding: 5px 0;
            }
        }
        
    }

    .sdcHeadingWrapper {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
    .sdcHeading {
        // padding-top: 2px;
        width: 75%;
    }

    .sdcWeb {
        color: white !important;
        text-decoration: underline !important;
    }

    .content {
        margin-top: 50px;
        p {
            font-family: 'Raleway', sans-serif;
            font-weight: 400;
        }
        .buy-tickets-wrapper {
            margin-top: 50px;
            a {
                width: 100%;
            }
            svg {
                margin-right: 10px;
            }
        }
    }

    .swiper-slide {
        img {
            width: 250px;
            height: 250px;
            object-fit: cover;
        }
    }

    .content {
        margin-top: 50px;
        p {
            font-family: 'Raleway', sans-serif;
            font-weight: 400;
        }
        h3 {
            text-align: center;
            text-transform: uppercase;
        }
    }

    .text-center {
        text-align: center;
        margin-top: 100px;
    }


}